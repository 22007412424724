import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import styled from "@emotion/styled"
import Image from "gatsby-image"

import StoreContext from "../context/StoreContext"
import InterfaceContext from "../context/InterfaceContext"
import TakeawayLayout from "../components/Layout/TakeawayLayout"
import SEO from "../components/seo"
import MenuTile from "../components/ui/MenuTile"
import HeaderImg from "../assets/takeaway-header.jpg"
import TakeawayTypeSelection from "../components/TakeawayTypeSelection"
import CartDrawer from "../components/CartDrawer"
import CategoryMenu from "../components/CategoryMenu"

import { productAdded, checkoutStarted } from "../services/analytics"
import mq from "../utils/mq"

const Remove = styled.div`
  cursor: pointer;
  ${props => props.marginRight && `margin-right: 0.5rem;`}
`

const Banner = styled.div`
  position: relative;
  height: 40vh;
  width: 100%;
  margin-top: -60px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: calc(3 * ${props => props.theme.spacing.padding.content});

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      ${props => props.theme.colors.background}00 0%,
      ${props => props.theme.colors.background}40 50%,
      ${props => props.theme.colors.background}ff 100%
    );
    z-index: 2;
  }

  .title {
    z-index: 3;
    font-family: "Poppins", sans-serif;
    font-size: ${props => props.theme.typography.major};
    text-transform: uppercase;
    color: white;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
`

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  color: white;
`

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  flex-direction: column;

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: row;
  }
`

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  color: white;

  padding-top: 43px;
  padding-bottom: 200px;

  flex-grow: 1;

  padding-left: ${props => props.theme.spacing.padding.content};
  padding-right: ${props => props.theme.spacing.padding.content};
`

const RicePopUp = styled.div`
  position: fixed;
  z-index: 10010;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1rem;

  display: flex;

  .body {
    .header {
      display: flex;
      justify-content: space-between;
      font-size: ${props => props.theme.typography.header};
      margin-bottom: 2rem;
    }

    color: white;
    background-color: ${props => props.theme.colors.brand};
    padding: 2rem;
    max-width: 976px;
    margin: auto;

    .continue {
      border-radius: 3px;
      cursor: pointer;
      float: right;
      width: max-content;
      padding: 0.5rem;
      background-color: ${props => props.theme.colors.secondary};
    }
  }
`

const CategoryContainer = styled.section`
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    padding-top: 100px;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MenuItem = styled.div`
  cursor: pointer;
  display: flex;

  border-top: 1px solid gray;
  padding: 1rem;

  &:last-of-type {
    border-bottom: 1px solid gray;
  }

  &:hover {
    .add-to-cart {
      background-color: ${props => props.theme.colors.secondary};
    }
  }

  .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 200ms;

    height: 30px;
    width: 30px;

    border-radius: 2px;
    padding: 10px;
    margin-right: 1rem;

    font-size: 20px;
  }

  .content {
    flex-direction: column;
    .title {
      font-size: 18px;
    }

    .description {
      color: lightgray;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .price {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

const Prompt = styled.div`
  position: fixed;
  width: calc(100vw - 2rem);
  left: 1rem;
  bottom: 1rem;
  padding: 2rem;
  background-color: ${props => props.theme.colors.brand};
  color: white;
  border-radius: 3px;
  border: 1px solid gray;

  ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`

class TakeawayPage extends React.Component {
  state = {
    type: "initial",
    time: null,
    activeSection: "",
    prompt: "",
  }

  componentDidMount() {
    this.sections = document.querySelectorAll("section.menu-cat")
    const currentSection = this.findSection()
    this.setState({
      activeSection: currentSection.id,
    })
    window.addEventListener("scroll", this.scrollAgent)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollAgent)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.prompt && prevState.prompt !== this.state.prompt) {
      setTimeout(() => {
        this.setState({
          prompt: "",
        })
      }, 2000)
    }
  }

  prompt(name) {
    this.setState({
      prompt: name,
    })
  }

  findSection = () => {
    const currentPos = window.pageYOffset + 50
    const sections = this.sections

    let currentSection = sections[0]
    sections.forEach(element => {
      if (
        element.offsetTop < currentPos &&
        element.offsetTop + element.offsetHeight > currentPos
      ) {
        currentSection = element
      }
    })

    return currentSection
  }

  scrollAgent = e => {
    const currentSection = this.findSection()
    this.setState({
      activeSection: currentSection.id,
    })
  }

  dismissRice = () => {
    this.setState({
      showRice: false,
    })
  }

  handleNoRice = cart => {
    checkoutStarted(cart)
    navigate("/checkout")
  }

  handleCheckout = cart => {
    if (cart.items.find(i => i.menuItem.posNumber === 20503)) {
      checkoutStarted(cart)
      navigate("/checkout")
    } else {
      this.setState({
        showRice: true,
      })
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            contentfulNavigationMenu(name: { eq: "Takeaway Menu" }) {
              items {
                ... on ContentfulMenuItemCategory {
                  id
                  name
                  slug
                  menuItems {
                    id
                    name
                    contentful_id
                    description {
                      description
                    }
                    price
                  }
                }
              }
            }
            riceItem: contentfulMenuItem(posNumber: { eq: 20503 }) {
              id
              name
              contentful_id
              description {
                description
              }
              price
            }
          }
        `}
        render={({ contentfulNavigationMenu: menu, riceItem }) => (
          <TakeawayLayout>
            <SEO
              title="Bestil Indisk Takeaway Online"
              description="Vi tilbyder levering eller afhentning i restauranten i København. Vi tilbyder indiske favoritter som Butter Chicken, Lamb Spinach, Daal, etc. og laver friske Naan brød i vores tandoori ovne."
            />
            <Banner>
              <img src={HeaderImg} />
              <h1 className="title">Takeaway</h1>
            </Banner>
            <InterfaceContext.Consumer>
              {({ isCartOpen, toggleCart }) => (
                <StoreContext.Consumer>
                  {({ cart, addLineItem }) => (
                    <div>
                      Due to COVID-19 we have been forced to close down. We
                      apologize for the inconvenience and hope to welcome you
                      back soon.
                      {/*<MenuContainer>
                        <CategoryMenu
                          categories={menu.items}
                          activeSection={this.state.activeSection}
                        />
                        <MenuContent>
                          {menu.items.map(node => (
                            <CategoryContainer
                              key={node.id}
                              id={node.slug}
                              className="menu-cat"
                            >
                              <h2>{node.name}</h2>
                              <ItemsContainer>
                                {node.menuItems.map(
                                  ({
                                    id,
                                    contentful_id,
                                    name,
                                    description,
                                    price,
                                  }) => (
                                    <MenuItem
                                      key={id}
                                      onClick={() => {
                                        productAdded({ price, name })
                                        addLineItem(contentful_id)
                                        this.prompt(name)
                                      }}
                                    >
                                      <div className="add-to-cart">
                                        <i className="fal fa-plus" />
                                      </div>
                                      <div className="content">
                                        <div className="title">{name}</div>
                                        <div className="description">
                                          {description &&
                                            description.description}
                                        </div>
                                        <div className="price">
                                          DKK {price.toFixed(2)}
                                        </div>
                                      </div>
                                    </MenuItem>
                                  )
                                )}
                              </ItemsContainer>
                            </CategoryContainer>
                          ))}
                        </MenuContent>
                        <CartDrawer
                          isOpen={isCartOpen}
                          toggleCart={toggleCart}
                          onCheckout={() => this.handleCheckout(cart)}
                        />
                      </MenuContainer>
                      */}
                      {this.state.showRice && (
                        <RicePopUp>
                          <div className="body">
                            <div className="header">
                              Would you like to add rice to your order?
                              <Remove onClick={this.dismissRice}>&#215;</Remove>
                            </div>
                            <MenuItem
                              key={riceItem.id}
                              onClick={() => {
                                productAdded(riceItem)
                                addLineItem(riceItem.contentful_id)
                                this.prompt(riceItem.name)
                                this.dismissRice()
                              }}
                            >
                              <div className="add-to-cart">
                                <i className="fal fa-plus" />
                              </div>
                              <div className="content">
                                <div className="title">{riceItem.name}</div>
                                <div className="description">
                                  {riceItem.description &&
                                    riceItem.description.description}
                                </div>
                                <div className="price">
                                  DKK {riceItem.price.toFixed(2)}
                                </div>
                              </div>
                            </MenuItem>
                            <div
                              onClick={() => this.handleNoRice(cart)}
                              className="continue"
                            >
                              No thanks
                            </div>
                          </div>
                        </RicePopUp>
                      )}
                    </div>
                  )}
                </StoreContext.Consumer>
              )}
            </InterfaceContext.Consumer>
            {this.state.prompt && (
              <Prompt>{this.state.prompt} was added to your cart</Prompt>
            )}
          </TakeawayLayout>
        )}
      />
    )
  }
}

export default TakeawayPage
