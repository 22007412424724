import React from 'react'
import styled from '@emotion/styled'
import Image from 'gatsby-image'

import Button from './Button'
import SpacingComponent from './SpacingComponent'
import Link from '../../utils/Link'

const ItemLink = styled(Link)`
  height: 100%;
`

const MenuItemCard = styled(SpacingComponent)`
  padding: 2rem;
  height: 100%;
  overflow: hidden;
  .content {
    cursor: pointer;

    z-index: 1;

    .inset {
      left: 0;
      right: 0;
      padding: 1rem;
    }

    h2 {
      font-size: calc(1rem + .3vw);
      text-transform: uppercase;
      font-weight: 300;
      transition: transform 200ms;
      margin: 0;
    }
  }
`

const Img = styled(Image)`
  z-index: 0;
  height: 80%;
  width: 100%;
`

const Placeholder = styled.div`
  height: 80%;
  width: 100%;
`

const MenuTile = props => {
  const { image, children, to } = props

  const module = (
    <MenuItemCard>
      {image ?
        <Img fluid={image.fluid} />
      :
        <Placeholder />
      }
      {children}
    </MenuItemCard>
  )

  if (to) {
    return (
      <ItemLink to={to}>
        {module}
      </ItemLink>
    )
  }

  return module
}

const Content = ({
  children
}) => (
  <div className="content">
    <div className="inset">
      {children}
    </div>
  </div>
)

MenuTile.Content = Content

export default MenuTile


