import React from 'react'
import styled from '@emotion/styled'

import Button from "./ui/Button"
import TimePicker from "./ui/TimePicker"

const Back = styled.div`
  
`

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: ${props => props.theme.spacing.padding.content};

  .delivery-options {
    display: flex;

    ${Button} {
      i {
        margin-left: 4rem;
      }
    }
  }
`

const Prompt = styled.div`
  margin: 1rem 0;
`

class TakeawayTypeSelection extends React.Component {
  state = {
    type: 'init'
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.cart.isDelivery) {
      this.setState({
        type: 'delivery'
      })
    }
  }

  componentDidMount() {
    if (this.props.cart.isDelivery) {
      this.setState({
        type: 'delivery'
      })
    }
  }

  handlePutDelivery = () => {
    this.props.onDeliverySelected()
  }

  handlePutPickup = () => {
    this.setState({
      type: 'pickup'
    })
  }

  handleBack = () => {
    if (this.state.type === 'delivery') {
      this.props.onDeleteDelivery()
    }

    this.setState({
      type: 'init'
    })
  }

  render() {
    return (
      <ControlsContainer>
        {(this.state.type === 'init') ? (
            <div className="delivery-options">
              <Button 
                outline
                margin=".5rem 1rem .5rem 0"
                onClick={this.handlePutPickup}
              >
                Pick up <i className="fal fa-shopping-bag" />
              </Button>
              <Button
                outline
                onClick={this.handlePutDelivery}
              >
                Delivery <i className="fal fa-bicycle" />
              </Button>
            </div>
        ) : (
          <div>
            <Back onClick={this.handleBack}>
              <i className="fal fa-long-arrow-left"/> Go back
            </Back>
            {(this.state.type === 'delivery') ? (
              <Prompt>Select a delivery time</Prompt>
            ) : (
              <Prompt>Select a pickup time</Prompt>
            )}
            <TimePicker
              isDelivery={this.props.cart.isDelivery}
              selectedTime={this.state.time}
              onTimeSelected={this.props.onTimeSelected}
            />
          </div>
        )}
      </ControlsContainer>
    )
  }
}

export default TakeawayTypeSelection
